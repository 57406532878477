<template>
  <div class="col-sm-12">
    <div class="card mb-3" v-if="matchInfo.code != 'VERIFY_FAIL'">
      <div class="card-header position-relative">
        <h3 class="mb-0">Confirm Add Payee Check</h3>
      </div>
      <div class="card-body min-vh">
        <div class="row justify-content-xl-center">
          <div class="col-xl-8">
            <div class="payee-confirm">
              <el-form label-position="top" size="mini">
                <el-form-item
                  label="Business account name"
                  v-if="payeeInfo.type === 'company'"
                >
                  <span class="payee-content">{{ payeeInfo.name }}</span>
                  <el-button
                    @click="
                      $swal(
                        `What's a match?`,
                        `This means we've checked and
                        the name and account type
                        provided match the details
                        held by the payee's bank.
                        Although this does not prevent
                        all types of fraud, this will help
                        give you greater reassurance
                        that you are paying the correct
                        business.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>
                <el-form-item
                  label="First name"
                  v-if="payeeInfo.type === 'person'"
                >
                  <span class="payee-content">{{ payeeInfo.fname }}</span>
                  <el-button
                    @click="
                      $swal(
                        `What's a match?`,
                        `This means we've checked and
                      the name and account type
                      provided match the details
                      held by the payee's bank.
                      Although this does not prevent
                      all types of fraud, this will help
                      give you greater reassurance
                      that you are paying the correct
                      person.`,
                        'question'
                      )
                    "
                    class="btn-icon-question"
                    type="text"
                    icon="el-icon-question"
                  ></el-button>
                </el-form-item>
                <el-form-item
                  label="Last name"
                  v-if="payeeInfo.type === 'person'"
                >
                  <span class="payee-content">{{ payeeInfo.lname }}</span>
                </el-form-item>
                <el-form-item>
                  <el-alert
                    v-if="
                      matchInfo.code.includes('MATCHED') &&
                      !matchInfo.code.includes('NOT_MATCHED')
                    "
                    title="Account name is a match"
                    type="success"
                    :closable="false"
                    show-icon
                  >
                  </el-alert>
                  <el-alert
                    v-if="matchInfo.code.includes('CLOSE_MATCH')"
                    title="Account name is close match"
                    type="warning"
                    :closable="false"
                    show-icon
                  >
                  </el-alert>
                  <el-alert
                    v-if="matchInfo.code.includes('NOT_MATCHED')"
                    title="Account name dose not match"
                    type="error"
                    :closable="false"
                    show-icon
                  >
                  </el-alert>
                </el-form-item>
                <el-form-item label="Account Number">
                  <span class="payee-content">{{
                    payeeInfo.accountnumber
                  }}</span>
                </el-form-item>
                <el-form-item label="Sort Code">
                  <span class="payee-content">{{ payeeInfo.sortcode }}</span>
                </el-form-item>
                <el-form-item label="Reference">
                  <span class="payee-content">{{ payeeInfo.reference }}</span>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-content-xl-center">
          <div class="col-xl-12 text-center">
            <el-button type="primary" @click="confirmAddPayee"
              >Confirm add payee</el-button
            >
            <el-button @click="cancel">Cancel</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3" v-if="matchInfo.code == 'VERIFY_FAIL'">
      <div class="card-header position-relative">
        <h3 class="mb-0">Unable to verify beneficiary detail at this moment</h3>
      </div>
      <div class="card-body min-vh">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div class="payee-confirm text-center">
              <div class="text-center">
                <p class="match-icon match-error">
                  <i class="el-icon-warning"></i>
                </p>
              </div>
              <p>
                Please wait a minute and try again or do you want to continue
                add this beneficiary without verify first.
              </p>
              <p class="text-xs text-red">
                beneficiary without verified can lead to fail payment.
              </p>
              <div class="text-center">
                <p class="match-title3">You entered</p>
                <p class="match-name">
                  <span class="text-xs text-black-50">Name</span> <br />
                  {{ Name }}
                </p>
                <p class="match-name">
                  <span class="text-xs text-black-50">Bank Name</span> <br />
                  {{ payeeInfo.bankname }}
                </p>
                <p class="match-name">
                  <span class="text-xs text-black-50">Account number </span>
                  <br />
                  {{ payeeInfo.accountnumber }}
                </p>
                <p class="match-name">
                  <span class="text-xs text-black-50">Sortcode</span> <br />
                  {{ payeeInfo.sortcode }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-content-xl-center">
          <div class="col-xl-12 text-center">
            <el-button type="danger" @click="confirmAddPayee"
              >Add beneficiary with no verify</el-button
            >
            <el-button @click="cancel">OK</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="warning-dialog"
      :visible.sync="adsWarning"
    >
      <div class="row justify-content-xl-center">
        <div class="col-xl-12">
          <img
            style="width: 100%"
            class="img-autosize img-center"
            src="/img/modal/ads_warning.png"
            alt=""
          />
          <br />
          <div
            role="alert"
            class="el-alert el-alert-custom el-alert--warning is-light"
          >
            <div class="el-alert__content">
              <span class="el-alert__title is-bold"
                >Protect your money from fraud and scams</span
              ><!---->
              <p class="el-alert__description">
                We want to remind you to be vigilant regarding potential fraud
                before transferring funds
              </p>
              <br />
              <p class="el-alert__description">
                When making a payment to an individual or organisation, take a
                moment to consider
              </p>
              <p class="el-alert__description">whether:</p>
              <br>
              <p class="el-alert__description">
                - The person you're paying is who you were expecting to<br />
                - The payment is for genuine goods or services<br />
                - The person or business you are paying is legitimate<br />
                - If anything seems off, do not proceed with the transfer
              </p>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adsWarning = false">Cancel</el-button>
        <el-button type="warning" @click="confirmOKAddPayee">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    payeeInfo: {
      type: Object,
    },
    matchInfo: {
      type: Object,
    },
  },
  computed: {
    Name() {
      return (
        this.payeeInfo.name || this.payeeInfo.fname + " " + this.payeeInfo.lname
      );
    },
  },
  data() {
    return {
      adsWarning: false,
    };
  },
  methods: {
    confirmAddPayee() {
      this.adsWarning = true;
    },
    confirmOKAddPayee() {
      this.adsWarning = false;
      this.$emit("confirmed");
    },
    cancel() {
      this.$emit("cancelled");
    },
  },
};
</script>

<style lang="scss">
.el-alert-custom {
  .el-alert__title {
    font-size: 18px;
  }
  .el-alert__description {
    font-size: 14px;
    margin: 5px 0 0;
  }
}
.warning-dialog {
  border-radius: 16px;
  .el-dialog__header {
    display: none !important;
  }

}
.payee-confirm {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 20px;

  .el-form-item__label {
    padding: 0;
  }
  .el-form-item--mini {
    margin-bottom: 20px !important;
  }
  .el-form-item__content {
    span.payee-content {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .match-icon {
    font-size: 50px;
    line-height: 1;
  }

  .match-title {
    font-size: 20px;
    color: #409eff;
    font-weight: 600;
    word-break: normal;
    word-wrap: break-word;
  }

  .match-warning {
    color: #ffc107 !important;
  }

  .match-error {
    color: #f5222d !important;
  }

  .match-title2 {
    font-weight: 600;
  }

  .match-title3 {
    font-weight: 600;
    color: #409eff;
  }

  .match-name {
    font-weight: 600;
  }

  .match-detail {
    word-break: normal;
    word-wrap: break-word;
  }
}
</style>
